import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";
import { ContestService as ApiContestService } from "@sata/cdg-api";

@Injectable()
export class ContestService extends ApiContestService {
  public isContest() {
    return environment.contest;
  }
}
