<div *ngIf="store.loading$ | async" class="loading"></div>
<div #controlbar fxLayout="column" fxLayoutAlign="space-between stretch" style="height: 100%; min-height: 100%">
  <mat-tab-group style="flex: 8; overflow-y: auto" mat-stretch-tabs (selectedTabChange)="selectedTabChange($event)">
    <mat-tab>
      <div class="p-16">
        <ng-template mat-tab-label>
          <div class="tab-indicator">1</div>
          <smallstack-i18n data="@@sata.gundesigner.wizard.component.settings"></smallstack-i18n>
        </ng-template>
        <design-configuration [store]="store"></design-configuration>
      </div>
    </mat-tab>
    <mat-tab style="height: 100%; padding-bottom: 20px">
      <ng-template mat-tab-label>
        <div class="tab-indicator">2</div>
        <smallstack-i18n data="@@sata.gundesigner.wizard.component.design"></smallstack-i18n>
      </ng-template>
      <images-and-text [store]="store"></images-and-text>
    </mat-tab>
  </mat-tab-group>
  <price-box [store]="store" *ngIf="!contestService.isContest(); else contestSubmit"></price-box>
</div>

<ng-template #contestSubmit>
  <div fxLayout="column" fxLayoutAlign="center stretch" style="margin-top: 50px">
    <button
      mat-raised-button
      [disabled]="!editable"
      type="button"
      color="accent"
      style="height: 100px"
      (click)="store.checkDesign()"
    >
      Submit Design
    </button>
  </div>
</ng-template>
