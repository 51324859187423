import { ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatTabChangeEvent, MatTabGroup } from "@angular/material/tabs";
import { Subscription } from "rxjs";
import { GunCanvasImageEditingMode } from "./canvas/GunGame";
import { ConfigurationComponent } from "./configuration.component";
import { DesignStore } from "./DesignStore";
import { GoogleAnalyticsService } from "./ga.service";

@Component({
  selector: "images-and-text",
  templateUrl: "./images-and-text.component.html",
  styleUrls: ["./images-and-text.component.scss"]
})
export class ImagesAndTextComponent implements OnInit, OnDestroy {
  @Input()
  public store: DesignStore;

  @ViewChild(MatTabGroup) imageAndTextTabs: MatTabGroup;

  public isImageLibraryShown = false;
  public isTextFieldEditorShown = false;
  public isInlineTextFieldEditorShown = false;

  private subscription: Subscription;
  private textId: string;

  constructor(
    private analyticsService: GoogleAnalyticsService,
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public designStore: DesignStore,
    @Optional() public dialogRef: MatDialogRef<ConfigurationComponent>
  ) {
    this.store = designStore;
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }
  public ngOnInit(): void {
    this.subscription = this.store.configChanges$.subscribe((cfg) => {
      if (cfg.selectedLayerId !== undefined && this.dialogRef) this.dialogRef.close();
      const t = cfg.design.sides[cfg.visibleSide].layers.find((l) => l.id === cfg.selectedLayerId);
      const nextTextId = t && t.text ? t.id : undefined;

      if (this.textId !== nextTextId) {
        this.textId = nextTextId;
        if (nextTextId) this.showInlineTextFieldEditor();
        else this.hideInlineTextFieldEditor();
      }
      this.cdr.detectChanges();
    });
  }

  public onTabChanged(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.analyticsService.event("userClickAction", "showImageLibrary", "true");
      const selectedLayer = this.store.getSelectedLayer();
      if (selectedLayer) this.store.setSelectedLayer(selectedLayer.id);
      this.store.changeMode(GunCanvasImageEditingMode.none);
    } else {
      this.analyticsService.event("userClickAction", "showTextEditor", "true");
      this.showTextFieldEditor();
    }
  }

  public setTab(index) {
    this.imageAndTextTabs.selectedIndex = index;
    if (index === 0) this.showImageLibrary();
    else this.showInlineTextFieldEditor();
  }

  public showImageLibrary() {
    this.analyticsService.event("userClickAction", "showImageLibrary", "true");
    this.isTextFieldEditorShown = false;
    this.isInlineTextFieldEditorShown = false;
    this.isImageLibraryShown = true;
  }

  public hideImageLibrary() {
    this.isImageLibraryShown = false;
  }

  public showTextFieldEditor() {
    if (this.isInlineTextFieldEditorShown === false) {
      this.analyticsService.event("userClickAction", "showTextEditor", "true");
      this.isImageLibraryShown = false;
      this.isTextFieldEditorShown = true;
      this.isInlineTextFieldEditorShown = false;
    }
  }

  public showInlineTextFieldEditor() {
    this.isImageLibraryShown = false;
    this.isTextFieldEditorShown = false;
    this.isInlineTextFieldEditorShown = true;
  }

  public hideTextFieldEditor() {
    this.analyticsService.event("userClickAction", "showTextEditor", "false");
    this.isTextFieldEditorShown = false;
  }

  public hideInlineTextFieldEditor() {
    this.isInlineTextFieldEditorShown = false;
  }

  public close() {
    if (this.dialogRef) this.dialogRef.close();
  }
}
