import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { CommonService, MagentoStore } from "@sata/cdg-api";
import { PriceDto, PriceZoneDto, ProductDto, ProductVariantDto } from "@smallstack/axios-api-client";
import { ClientProductService } from "@smallstack/product-components";
import { combineLatest, Subscription } from "rxjs";
import { ContestService } from "../services/contest.service";
import { DesignStore } from "./DesignStore";

@Component({
  selector: "price-box",
  templateUrl: "./pricebox.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceBoxComponent implements OnInit, OnDestroy {
  public product: ProductDto;
  public priceZone: PriceZoneDto;
  public productVariant: ProductVariantDto;
  public editable: boolean;

  @Input()
  public store: DesignStore;

  private subscription: Subscription;

  constructor(
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private clientProductService: ClientProductService,
    public contestService: ContestService
  ) {}

  public ngOnDestroy(): void {
    if (this.subscription) this.subscription.unsubscribe();
  }

  public async submitDesign() {
    await this.store.checkDesign();
  }

  public ngOnInit(): void {
    this.subscription = combineLatest([
      this.store.designChanges$,
      this.store.productChanges$,
      this.store.productVariantChanges$
    ]).subscribe(async () => {
      this.product = this.store.product;
      this.productVariant = this.store.config.design.productVariant;
      this.editable = this.store.editable;
      await this.calculatePriceZone();
      this.cdr.markForCheck();
    });
  }

  private async calculatePriceZone() {
    if (this.product && this.productVariant) {
      const prices: PriceDto[] = this.clientProductService.calculatePrices(
        this.product,
        this.productVariant,
        this.product.options
      );
      if (!prices) throw new Error("no prices found for product & variant combination!");
      const magentoStore: MagentoStore = await this.commonService
        .getMagentoStore({ storeCode: this.store.config.design.magentoStoreCode })
        .toPromise();
      if (!magentoStore)
        throw new Error(
          "no magento store configuration found for store code: " + this.store.config.design.magentoStoreCode
        );
      const price: PriceDto = prices.find((p) => p.priceZoneId === magentoStore.priceZoneId);
      if (!price) throw new Error("no price found for price zone id: " + magentoStore.priceZoneId);
      const priceZone: PriceZoneDto = await this.commonService.getPriceZone({ id: price.priceZoneId }).toPromise();
      if (!priceZone) throw new Error("no price zone found for price zone id: " + magentoStore.priceZoneId);
      this.priceZone = priceZone;
      this.cdr.markForCheck();
    }
  }
}
