import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
  title: string;
  message: string;
}

@Component({
  selector: "submit-design-dialog",
  templateUrl: "submit-design-dialog.component.html"
})
export class SubmitDesignDialog {
  constructor(public dialogRef: MatDialogRef<SubmitDesignDialog>, @Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
