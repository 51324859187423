import { Component, Input, OnChanges } from "@angular/core";
import { Price, Product, ProductOption, ProductOptionValue, ProductVariant } from "@sata/cdg-api";
import { PriceZoneDto } from "@smallstack/axios-api-client";
import { LocaleService } from "@smallstack/i18n-components";

@Component({
  selector: "pim-product-option-price",
  templateUrl: "./pim-product-option-price.component.html"
})
export class PIMProductOptionPriceComponent implements OnChanges {
  @Input()
  public product: Product;

  @Input()
  public priceZone: PriceZoneDto;

  @Input()
  public option: string;

  @Input()
  public productVariant: ProductVariant;

  public price = 0;
  public currency: string;
  public locale: string;

  constructor(localeService: LocaleService) {
    localeService.currentLocale$.subscribe((lang: string) => (this.locale = lang));
  }

  public ngOnChanges() {
    if (this.product && this.priceZone) {
      const productOption: ProductOption = this.product.options.find((o) => o.key === this.option);
      if (!productOption) throw new Error("Could not find product option for " + this.option);
      const value = this.productVariant.options[this.option];
      const productOptionValue: ProductOptionValue = productOption.values.find(
        (productValue: ProductOptionValue) => productValue.value === value
      );
      if (!productOptionValue) throw new Error("Could not find product option value for " + this.option + "=" + value);
      this.currency = this.priceZone.currency;
      if (productOptionValue.prices) {
        const price: Price = productOptionValue.prices.find((p) => p.priceZoneId === this.priceZone.id);
        if (price) this.price = price.value / 100;
        else this.price = 0;
      } else this.price = 0;
    }
  }
}
