import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional
} from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DesignStatusEvent } from "@sata/cdg-api";
import { ProductDto } from "@smallstack/axios-api-client";
import { Subscription } from "rxjs";
import { ContestService } from "../services/contest.service";
import { DesignStore } from "./DesignStore";
import { getNozzleTypes, isHVLP } from "./functions";

@Component({
  selector: "design-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationComponent implements OnInit, OnDestroy, AfterViewInit {
  get nozzleSizes(): string[] {
    const nozzleTypes = getNozzleTypes(this.product);
    if (nozzleTypes) return nozzleTypes.filter((n) => n.indexOf(this.nozzleType) !== -1);
    return [];
  }
  @Input()
  public store: DesignStore;

  public editable: boolean;
  public isImageLibraryShown = false;
  public isTextFieldEditorShown = false;
  public isInlineTextFieldEditorShown = false;
  public nozzleType: "HVLP" | "RP";
  public locale: string;
  public color: string;
  public knobColor = "chrom";
  public bodyType: string;
  public vm010: string;
  public nozzleSize: string;
  public product: ProductDto;
  public productCode: string;
  public cpWidth: string;

  private subscription: Subscription = new Subscription();

  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() @Inject(MAT_DIALOG_DATA) public designStore: DesignStore,
    @Optional() public dialogRef: MatDialogRef<ConfigurationComponent>,
    private element: ElementRef,
    public contestService: ContestService
  ) {
    this.store = designStore;
  }

  public ngAfterViewInit(): void {
    this.cpWidth = `${this.element.nativeElement.offsetParent.offsetWidth - 48}px`;
    this.cdr.markForCheck();
  }

  public ngOnInit() {
    this.subscription.add(
      this.store.configChanges$.subscribe((cfg) => {
        this.color = cfg && cfg.design && cfg.design.color;
        this.editable = cfg && cfg.design && cfg.design.latestStatus.status === DesignStatusEvent.StatusEnum.Created;
        this.nozzleType = cfg && cfg.design && isHVLP(cfg.design) ? "HVLP" : "RP";
        this.nozzleSize = cfg && cfg.design && cfg.design.productVariant && cfg.design.productVariant.options.VM500;
        this.bodyType = cfg && cfg.design && cfg.design.productVariant && cfg.design.productVariant.options.VM010;
        this.knobColor = cfg && cfg.design && cfg.design.productVariant && cfg.design.productVariant.options.VM200;
        this.productCode = cfg && cfg.design && cfg.design.productCode;
        this.cdr.markForCheck();
      })
    );
    this.subscription.add(
      this.store.productChanges$.subscribe((product) => {
        this.product = product;
        this.cdr.markForCheck();
      })
    );
  }

  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
      delete this.subscription;
    }
  }

  public async changeBodyType(bodyType: string) {
    await this.store.changeBody(bodyType);
  }

  public async updateNozzleSize(size: string) {
    await this.store.updateNozzleSize(size);
  }

  public async updateNozzleType(type: "HVLP" | "RP"): Promise<void> {
    await this.store.updateNozzleType(type);
  }

  public async changeGunModel(productCode: string): Promise<void> {
    await this.store.updateProductCode(productCode);
  }

  public changeGroundColor(color: string) {
    this.store.colorChanged(color);
  }

  public changeKnobColor(color: "chrom" | "schwarz") {
    // this.knobColor = color;
    this.store.changeKnobColor(color);
  }

  public close() {
    if (this.dialogRef) this.dialogRef.close();
  }
}
