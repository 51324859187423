<div class="container" style="position: relative">
  <div
    *ngIf="!!networkActivity"
    style="
      position: fixed;
      top: 0px;
      left: 0px;
      bottom: 0px;
      right: 0px;
      background-color: rgba(255, 255, 255, 0.7);
      z-index: 1000;
    "
    fxLayout="row"
    fxLayoutAlign="center"
  >
    <div style="width: 20rem; align-items: center; display: flex; flex-direction: column; justify-content: center">
      <mat-progress-spinner *ngIf="!networkProgress" mode="indeterminate"> </mat-progress-spinner>
      <span>{{ networkActivity }}</span>
      <mat-progress-bar
        *ngIf="networkProgress"
        mode="determinate"
        color="accent"
        style="height: 0.3rem; padding-top: 0.5rem"
        [value]="networkProgress"
      >
      </mat-progress-bar>
    </div>
  </div>
  <div *ngIf="loadingDesignStatus !== 'ok'" style="width: 100%; line-height: 45px; text-align: center">
    <span [ngSwitch]="loadingDesignStatus">
      <div *ngSwitchCase="'loading'" fxLayout="column" fxLayoutAlign="center center">
        <smallstack-i18n
          data="@@sata.gundesigner.designer.component.loadingdesign"
          showMissingKey="false"
        ></smallstack-i18n>
      </div>
      <div *ngSwitchCase="'network-error'" fxLayout="column" fxLayoutAlign="center center">
        <smallstack-i18n data="@@sata.gundesigner.designer.component.networkerror"></smallstack-i18n>
      </div>
      <div *ngSwitchCase="'load-error'" fxLayout="column" fxLayoutAlign="center center">
        <smallstack-i18n data="@@sata.gundesigner.designer.component.loaderror"></smallstack-i18n>
        <button mat-raised-button color="accent" (click)="resetDesign()" *ngIf="!contestService.isContest()">
          <smallstack-i18n data="@@sata.gundesigner.resetdesign.startoveragain"></smallstack-i18n>
        </button>
      </div>
      <div *ngSwitchCase="'not-found'" fxLayout="column" fxLayoutAlign="center center">
        <smallstack-i18n data="@@sata.gundesigner.designer.component.notfound"></smallstack-i18n>
        <button mat-raised-button color="accent" (click)="resetDesign()">
          <smallstack-i18n data="@@sata.gundesigner.resetdesign.startoveragain"></smallstack-i18n>
        </button>
      </div>
    </span>
  </div>

  <div *ngIf="loadingDesignStatus === 'ok'" fxLayout="row" fxLayoutAlign="start" class="gundesigner-container">
    <div
      style="display: flex; flex-direction: row; width: 100%; flex: 1; overflow: hidden"
      [fxFlex]="screenTooSmall ? '100' : '70'"
    >
      <div *ngIf="assetPrefix" style="display: flex; flex-direction: column; flex: 1; height: 100%; overflow: hidden">
        <div fxLayout="row" fxLayoutAlign="space-between start" class="top-container">
          <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div class="sata-logo">
              <img src="/assets/sata_logo.svg" style="width: 100%" />
            </div>
            <div *ngIf="contestService.isContest()" class="contest-claim">
              <img src="/assets/sw_spray-your-way.png" style="width: 100%" />
            </div>
            <span
              *ngIf="!contestService.isContest()"
              data-test="sata-claim"
              style="margin-left: 10px; font-family: 'Trebuchet MS'; font-size: calc(0.45vw + 0.45vh + 0.3vmin)"
            >
              <smallstack-i18n data="@@sata.gundesigner.claim"></smallstack-i18n>
            </span>
            <div *ngIf="contestService.isContest()" class="sw-logo">
              <img src="/assets/sw-logo.jpeg" style="width: 100%" />
            </div>
          </div>
          <div class="top-right-container" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="20px">
            <!-- <a mat-icon-button color="default" [matTooltip]="tooltipDownloadDesign"
                            href="https://gundesigner.s3.eu-central-1.amazonaws.com/assets/{{productCode}}-design-template.pdf"
                            target="_blank" (click)="sendEvent('downloadTemplate')"
                            *ngIf="!screenTooSmall && !contestService.isContest()">
                            <img src="assets/download_design_template.svg" class="icon" />
                        </a> -->

            <a
              mat-icon-button
              [matMenuTriggerFor]="menu"
              color="default"
              [matTooltip]="tooltipDownloadDesign"
              *ngIf="!screenTooSmall"
            >
              <img src="assets/download_design_template.svg" class="icon" />
            </a>
            <button
              mat-icon-button
              color="default"
              (click)="copyDesignUrl()"
              *ngIf="!screenTooSmall"
              [matTooltip]="tooltipCopyLink"
            >
              <img src="assets/copy_design_link.svg" class="icon" />
            </button>
            <button
              mat-icon-button
              color="default"
              (click)="resetDesign()"
              *ngIf="loadingDesignStatus === 'ok' && !screenTooSmall"
              [matTooltip]="tooltipResetDesign"
            >
              <img src="assets/delete_design.svg" class="icon" />
            </button>
            <smallstack-locale-selector [locales]="locales" [showCountry]="false"></smallstack-locale-selector>
          </div>
        </div>
        <div style="overflow: hidden; height: 100%; width: 100%" [ngClass]="!screenTooSmall ? 'flexRowCC' : ''">
          <gun-component [store]="store" class="gun-component" [ngClass]="mode !== 'none' ? 'edit-mode' : 'view-mode'">
          </gun-component>
          <zoom-component [store]="store" *ngIf="!store.isSmallScreen()"></zoom-component>
        </div>
        <div *ngIf="screenTooSmall" class="mobile-stage-bar" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="side-switch" [ngClass]="selectedImageId !== undefined ? 'disabled' : ''">
            <img
              [style.background-color]="visibleSide === 'left' ? '#eaeaea' : ''"
              [src]="getImageUrl('assets/hud-assets/left-gun-button.png')"
              (click)="store.setVisibleSide('left')"
            />
            <img
              [style.background-color]="visibleSide === 'right' ? '#eaeaea' : ''"
              [src]="getImageUrl('assets/hud-assets/right-gun-button.png')"
              (click)="store.setVisibleSide('right')"
            />
          </div>
          <button
            mat-icon-button
            color="default"
            (click)="resetDesign()"
            style="width: 50px; height: 50px"
            *ngIf="loadingDesignStatus === 'ok' && screenTooSmall"
          >
            <img src="assets/delete_design.svg" class="icon" style="width: 40px; height: 40px; line-height: 50px" />
          </button>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="bottom-controls" width="100%">
          <div *ngIf="!screenTooSmall" class="side-switch" [ngClass]="selectedImageId !== undefined ? 'disabled' : ''">
            <img
              [style.background-color]="visibleSide === 'left' ? '#eaeaea' : ''"
              [src]="getImageUrl('assets/hud-assets/left-gun-button.png')"
              (click)="store.setVisibleSide('left')"
              data-test="controls-left"
            />
            <img
              [style.background-color]="visibleSide === 'right' ? '#eaeaea' : ''"
              [src]="getImageUrl('assets/hud-assets/right-gun-button.png')"
              (click)="store.setVisibleSide('right')"
              data-test="controls-right"
            />
          </div>
          <div
            *ngIf="!screenTooSmall && selectedImageId && editable"
            class="edit-image-controls"
            fxLayout="row"
            fxLayoutAlign="center"
            fxLayoutGap="40px"
          >
            <a
              class="edit-image-control"
              (click)="changeMode(GunCanvasImageEditingMode.move)"
              [ngClass]="mode === 'move' ? 'active' : 'inactive'"
            >
              <mat-icon>pan_tool</mat-icon>
              <smallstack-i18n data="@@sata.gundesigner.editimage.move"></smallstack-i18n>
            </a>
            <a
              class="edit-image-control"
              (click)="changeMode(GunCanvasImageEditingMode.scale)"
              [ngClass]="mode === 'scale' ? 'active' : 'inactive'"
            >
              <mat-icon>transform</mat-icon>
              <smallstack-i18n data="@@sata.gundesigner.editimage.scale"></smallstack-i18n>
            </a>
            <a
              class="edit-image-control"
              (click)="changeMode(GunCanvasImageEditingMode.rotate)"
              [ngClass]="mode === 'rotate' ? 'active' : 'inactive'"
            >
              <mat-icon>crop_rotate</mat-icon>
              <smallstack-i18n data="@@sata.gundesigner.editimage.rotate"></smallstack-i18n>
            </a>
          </div>
          <div
            fxLayout="row"
            [fxLayoutAlign]="screenTooSmall ? 'space-between center' : 'end'"
            fxLayoutGap="10px"
            [style]="screenTooSmall ? 'width: 100%;' : ''"
          >
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px" *ngIf="screenTooSmall">
              <a class="edit-image-control inactive" (click)="openWizard()">
                <mat-icon>{{ contestService.isContest() ? "invert_colors" : "settings" }}</mat-icon>
              </a>
              <a class="edit-image-control inactive" (click)="openLayers()">
                <mat-icon>palette</mat-icon>
              </a>
              <a mat-icon-button color="default" [matMenuTriggerFor]="menu" style="width: 50px; height: 50px">
                <img
                  src="assets/download_design_template.svg"
                  class="icon"
                  style="width: 40px; height: 40px; padding-top: 5px"
                />
              </a>
              <button mat-icon-button color="default" (click)="copyDesignUrl()" style="width: 50px; height: 50px">
                <img
                  src="assets/copy_design_link.svg"
                  class="icon"
                  style="width: 40px; height: 40px; line-height: 50px"
                />
              </button>
            </div>
            <a class="edit-image-control inactive" (click)="openDialog()" *ngIf="!screenTooSmall">
              <mat-icon>search</mat-icon>
              <smallstack-i18n data="@@sata.gundesigner.editimage.preview"></smallstack-i18n>
            </a>
            <div *ngIf="screenTooSmall">
              <button
                mat-flat-button
                color="accent"
                *ngIf="contestService.isContest()"
                (click)="openBottomSheet()"
                style="height: 50px"
              >
                Submit
              </button>
              <button
                mat-flat-button
                color="warn"
                style="height: 50px"
                *ngIf="!contestService.isContest()"
                (click)="openBottomSheet()"
              >
                <mat-icon>add_shopping_cart</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex="30" style="width: 25%; min-width: 400px; padding: 10px" *ngIf="!screenTooSmall">
      <wizard-component [store]="store"></wizard-component>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu" xPosition="before">
  <a
    mat-menu-item
    href="https://gundesigner-sherwin.s3.eu-central-1.amazonaws.com/assets/design-templates/CDG_template_front.tif"
    target="_blank"
    (click)="sendEvent('download contest template frontside tif')"
  >
    <span><smallstack-i18n data="@@sata.gundesigner.downloadtemplate.layeredfrontsidetiff"></smallstack-i18n></span>
  </a>
  <a
    mat-menu-item
    href="https://gundesigner-sherwin.s3.eu-central-1.amazonaws.com/assets/design-templates/CDG_template_back.tif"
    target="_blank"
    (click)="sendEvent('download contest template backside tif')"
  >
    <span><smallstack-i18n data="@@sata.gundesigner.downloadtemplate.layeredbacksidetiff"></smallstack-i18n></span>
  </a>
  <a
    mat-menu-item
    href="https://gundesigner-sherwin.s3.eu-central-1.amazonaws.com/assets/design-templates/template_handdrawing.pdf"
    target="_blank"
    (click)="sendEvent('download contest template handdrawing pdf')"
  >
    <span><smallstack-i18n data="@@sata.gundesigner.downloadtemplate.handdrawnpdf"></smallstack-i18n></span>
  </a>
</mat-menu>
