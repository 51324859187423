import { Component, Inject } from "@angular/core";
import { MAT_BOTTOM_SHEET_DATA } from "@angular/material/bottom-sheet";
import { DesignStore } from "./DesignStore";

export interface MobileBottomSheetData {
  store: DesignStore;
}

@Component({
  templateUrl: "./mobile-bottom-sheet.component.html"
})
export class MobileBottomSheetComponent {
  public designUrl: string = encodeURIComponent(window.location.href);

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: MobileBottomSheetData) {}
}
