import { GunDesignerEnvironment } from "./GunDesignerEnvironment";
import pkg from "../../../../package.json";

export const environment: GunDesignerEnvironment = {
  production: true,
  contest: false,
  name: "production",
  sataApiUrl: "https://sata-cdg-api.smallstack.cloud",
  smallstackApiUrl: "https://api.smallstack.com",
  smallstackTenantId: "5de175e5bf9f51000e0cdf42",
  version: pkg.version
};
